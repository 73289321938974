import { getwechatShare } from '@/api/home'

export const getwxShare= async (params,url,goodsType,path,txt)  =>{
  var res = await getwechatShare(params.url,params.goodsId,goodsType)
  if(res.code===0){
    let shareData ={}
    if( goodsType === 1){
      shareData = {
        title: '捷盈企业网站-入驻商城', // 分享标题
        desc: res.map.goods.title,
        link: url+path+res.map.goods.id,
        imgUrl: res.map.goods.firstPic, // 分享图标
      }
    }else if(goodsType === 0){
      shareData = {
        title: '捷盈企业网站-入驻商城', // 分享标题
        desc: res.map.goods.goodsName,
        link: url+path+res.map.goods.policyId,
        imgUrl: res.map.goods.goodsImg, // 分享图标
      }
    } else {
      shareData = {
        title: '捷盈企业网站-入驻商城', // 分享标题
        desc: txt,
        link: url+path,
        imgUrl: 'https://bkimg.cdn.bcebos.com/pic/9c16fdfaaf51f3de6c44263d9beef01f3b2979f1?x-bce-process=image/resize,m_lfit,w_536,limit_1/format,f_auto', // 分享图标
      }
    }
    var obj = {
      appId: res.map.appId,
      timestamp: res.map.timestamp,
      nonceStr: res.map.nonceStr,
      signature: res.map.signature,
    }
  wexinShare(obj,shareData)
  }
}
// http://gw-front.jieyingsoft.com/
// http://gw-front.jieyingsoft.com/#/goods/seckilldetails?productID=91
export const wexinShare = (obj, shareData) => {
  let { appId, timestamp, nonceStr, signature } = obj
  window.wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。（测试记得关掉）
    appId: appId, // 必填，公众号的唯一标识
    timestamp: timestamp, // 必填，生成签名的时间戳
    nonceStr: nonceStr, // 必填，生成签名的随机串
    signature: signature, // 必填，签名，见附录1y
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  })
  window.wx.ready(function () {
    // //分享到朋友圈”及“分享到QQ空间”
    console.log('shareData',shareData);
    window.wx.updateTimelineShareData({
      title: shareData.title, // 分享标题
      link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareData.imgUrl, // 分享图标
      success: function (res) {
        // 设置成功
        console.log('分享朋友圈成功返回的信息为:', res)
      },
    })
    //“分享给朋友”及“分享到QQ”
    window.wx.updateAppMessageShareData({
      title: shareData.title,
      desc: shareData.desc,
      link:  shareData.link,
      imgUrl: shareData.imgUrl,
      success: function () {
        // 设置成功
        console.log('分享成功')
      },
      cancel: function () {
        // 取消设置
        console.log('分享取消')
      },
    })
  })
  window.wx.error(function (res) {
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    console.log('验证失败返回的信息:', res)
  })
}
